import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import OnboardingComponent from "../components/ui/onboarding"

const Onboarding = () => {
  return (
    <Layout>
      <SEO title="Onboarding" />
      <OnboardingComponent />
    </Layout>
  )
}

export default Onboarding
