import React from "react"
import ReactPlayer from "react-player"
import classes from "./onboarding.module.css"

const Onboarding = () => (
  <div className={classes.container}>
    <div
      style={{
        fontSize: 28,
        fontWeight: 700,
        textAlign: "center",
        textDecoration: "underline",
      }}
    >
      Getting started with Seamless
    </div>

    <div>
      <div className={classes.title}>Pin Seamless</div>
      <div>
        <ReactPlayer
          url="https://youtu.be/aIDXriDX8sk"
          volume={0}
          muted
          playing={true}
        />
      </div>
    </div>

    <div>
      <div className={classes.title}>Browse like normal</div>
      <div className={classes.title} style={{ paddingTop: 0 }}>
        Everything is saved automatically
      </div>
      <div>
        <ReactPlayer url="https://youtu.be/c0L7kPtu0jA" />
      </div>
    </div>

    <div>
      <div className={classes.title}>You can also use shortcuts</div>
      <div>
        <div className={classes.shortcutContainer}>
          <div className={classes.description}>Open the extension:</div>
          <div className={classes.shortcut}>Ctrl + Shift + S</div>
        </div>

        <div className={classes.shortcutContainer}>
          <div className={classes.description}>Select sessions:</div>
          <div className={classes.shortcut}>Ctrl + Shift + ↓ or ↑</div>
        </div>

        <div className={classes.shortcutContainer}>
          <div className={classes.description}>Go to selected session:</div>
          <div className={classes.shortcut}>Ctrl + Shift + →</div>
        </div>
      </div>
    </div>
  </div>
)

Onboarding.propTypes = {}

Onboarding.defaultProps = {}

export default Onboarding
